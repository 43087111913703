table.dataTable tr.dtrg-group td {
  background-color: #e0e0e0;
}

table.dataTable tr.dtrg-group.dtrg-level-0 td {
  font-weight: bold;
}

table.dataTable tr.dtrg-group.dtrg-level-1 td,
table.dataTable tr.dtrg-group.dtrg-level-2 td {
  background-color: #f0f0f0;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 2em;
  font-size: 0.9em;
}

table.dataTable tr.dtrg-group.dtrg-level-2 td {
  background-color: #f3f3f3;
}
