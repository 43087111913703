@import "fontawesome/all";
@import "ionicons/ionicons";
@import "icheck-bootstrap/icheck-bootstrap";
@import "fullcalendar/fullcalendar";
@import "datatables/dataTables.bootstrap4";
@import "datatables/buttons.bootstrap4";
@import "datatables/fixedColumns.bootstrap4";
@import "datatables/fixedHeader.bootstrap4";
@import "datatables/responsive.bootstrap4";
@import "datatables/rowGroup.bootstrap4";
@import "datatables/scroller.bootstrap4";
@import "datatables/select.bootstrap4";
@import "select2/select2";
@import "pace/pace";
@import "icheck-bootstrap/icheck-bootstrap";
@import "bootstrap-datepicker/bootstrap-datepicker";
@import "adminlte/adminlte";

.has-error {
  color: #dc3545 !important;
}

a.btn:not(.btn-default):not(.btn-app) {
  color: white !important;
}