div.dts {
  display: block !important;
}
div.dts tbody th,
div.dts tbody td {
  white-space: nowrap;
}
div.dts div.dts_loading {
  z-index: 1;
}
div.dts div.dts_label {
  position: absolute;
  right: 10px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  text-align: right;
  border-radius: 3px;
  padding: 0.4em;
  z-index: 2;
  display: none;
}
div.dts div.dataTables_scrollBody {
  background: repeating-linear-gradient(45deg, #edeeff, #edeeff 10px, white 10px, white 20px);
}
div.dts div.dataTables_scrollBody table {
  z-index: 2;
}
div.dts div.dataTables_paginate,
div.dts div.dataTables_length {
  display: none;
}

div.DTS div.dataTables_scrollBody table {
  background-color: white;
}
